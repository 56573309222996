@import url('../../styles/global.css');

.pagination-container {
 display: flex;
 flex-direction: row;
 align-items: center;
 justify-content: center;
}
.button-pagination {
  background-color: transparent;
  color: var(--white-scale-300);
  font-weight: 700;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  border: none;
  width: 40px;
  padding: 8px;
  margin-right: 8px;
  border-radius: 32px;
  cursor: pointer;
}
.active-button-pagination {
  background-color: var(--blue-scale-200);
  color: var(--white-scale-300);
  font-weight: 700;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  border: none;
  width: 40px;
  padding: 8px;
  margin-right: 8px;
  border-radius: 32px;
  cursor: pointer;
}