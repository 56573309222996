.jobs-grid-team-object {
	background-color: var(--white-scale-100);
	border-radius: 16px;
	box-shadow: var(--bs-shadow-200);
  height: 20%;
	width: 20%;
	min-width: 270;
	padding:24px
}
.jobs-grid-team-object small {
	font-size: 12px;
}
.jobs-grid-team-object p {
	color: #000;
	font-weight: 600;
}
.jobs-grid-team-object-header {
	display: flex;
	flex-direction: row;
	align-items:center;
}
.jobs-grid-team-object-header-title-location {
	display: flex;
	flex-direction: column;
}

.jobs-grid-team-object-button-row {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top:32px
}
