@import "../../../styles/global.css";
@import "../../../styles/utility-classes.css";

.register-container {
  background-color: var(--white-scale-100);
  box-shadow: var(--bs-shadow-200);
  border-radius: 16px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50vw;
  min-width: 320px;
  height: 445px;
  min-height: 415px;
}

.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 600px) {
  img {
    display: none;
  }
}
