@import "../../../styles/global.css";
@import "../../../styles/utility-classes.css";

.input-component {
  border: 1px solid var(--black-scale-100);
  border-radius: 4px;
  height: 40px;
  max-width: 25em;
  width: 100%;
  margin-bottom: 2px;
}
