@import url(../../styles/global.css);

.container-header-homepage {
  width: 100%;
  margin: 32px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
}
.left-container-head-home-page {
  width: 50%;
  min-width: 320px;
}
.left-container-span-text {
  color: var(--white-scale-300);
}
.container-description-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home-page-middle-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;
  margin-bottom: 64px;

}
.floating-image {
  animation: floatAnimation 3s infinite ease-in-out;
}

@keyframes floatAnimation {
  0%, 100% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-8px);
  }
}

.homepage-container-mock-jobs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

h2 {
  color: var(--white-scale-200);
}
.call-to-action-home-page {
  margin: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
