@import url('./styles/global.css');

html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; 
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    background: linear-gradient(221.45deg, #0D68DF 23.45%, #042F67 94.19%);
    min-height: 100vh;
}

::-webkit-scrollbar {
    display: none;
  }