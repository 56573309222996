@import url("../../styles/global.css");

.container-congratulations {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 64px;
}

.container-congratulations-image {
  margin: 0 auto;
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white-scale-100);
  height: 250px;
  width: 250px;
  border-radius: 120px;
}
