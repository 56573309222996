@import '../../../styles/global.css';
@import '../../../styles/utility-classes.css';
@import '../../../styles/buttons.css';

* {
  padding:0;
  margin:0;
  }

  button{
    margin-top: 16px !important;
    height:40px;
  }

.btn-text {
  font-size: 14px;
  color: var(--white-scale-200);
}