@import url(../../styles/global.css);

.default-font {
  font-family: "Montserrat", sans-serif;
}
.header-jobs-page {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 32px;
}
.header-jobs-page-column {
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 300px;
}
.filter-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.top-of-filter-input{
  width: 30%;
  padding: 8px;
  background-color: var(--blue-scale-200);
  border-radius: 16px 16px 0 0;
}
.filter-input {
  padding: 8px;
  width: 30%;
  margin-bottom: 24px;
  height: 35px;
  border: none;
  border-radius: 6px;
  opacity: 0.9;
}
.filter-input:focus {
  /* transform: scale(1.1) translateY(-2px);
  transition: ease-in-out 0.2s; */
  outline: none !important;
  /* box-shadow: 0 0 10px #719ECE; */
}
.filter-jobs-main-container {
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* margin-inline: 85px; */
}


.jobs-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
p {
  margin-top: 8px;
  font-size: 14px;
  color: var(--white-scale-300);
}
.text-description-see-other-jobs {
  text-align: center;
}
h2 {
  font-size: 32px;
}
.see-plans {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
