/*
**INSTRUÇÕES**
1 == 0.5rem -> 8px
2 == 1rem -> 16px
3 == 1.5rem -> 24px
4 == 2rem -> 36px
5 == 3rem -> 48px
6 == 4rem -> 64px
7 == 4.5rem -> 72px
8 == 6rem -> 96px
*/

/* FLEX ELEMENTS */

.flex{
  display: flex;
}

.flex-wrap{
  flex-wrap: wrap;
}

.flex-nowrap{
  flex-wrap: nowrap;
}

.flex-row{
  flex-direction: row;
}

.flex-column{
  flex-direction: column;
}

.justify-center{
  justify-content: center;
}

.align-items{
  align-items: center;
}

/*padding top e bottom*/
.py-1 {
  padding: .5rem 0;
}
.py-2 {
  padding: 1rem 0;
}
.py-3 {
  padding: 1.5rem 0;
}
.py-4 {
  padding: 2rem 0;
}
.py-5 {
  padding: 3rem 0;
}
.py-6 {
  padding: 4rem 0;
}
.py-7 {
  padding: 4.5rem 0;
}
.py-8 {
  padding: 6rem 0;
}

/*padding left e right*/
.px-1 {
  padding: 0 0.5rem;
}
.px-2 {
  padding: 0 1rem;
}
.px-3 {
  padding: 0 1.5rem;
}
.px-4 {
  padding: 0 2rem;
}
.px-5 {
  padding: 0 3rem;
}
.px-6 {
  padding: 0 4rem;
}
.px-7 {
  padding: 0 4.5rem;
}
.px-8 {
  padding: 0 6rem;
}

/*padding top*/
.pt-1 {
  padding-top: .5rem;
}
.pt-2 {
  padding-top: 1rem;
}
.pt-3 {
  padding-top: 1.5rem;
}
.pt-4 {
  padding-top: 2rem;
}
.pt-5 {
  padding-top: 3rem;
}
.pt-6 {
  padding-top: 4rem;
}
.pt-7 {
  padding-top: 4.5rem;
}
.pt-8 {
  padding-top: 6rem;
}

/*padding bottom*/
.pb-1 {
  padding-bottom: .5rem;
}
.pb-2 {
  padding-bottom: 1rem;
}
.pb-3 {
  padding-bottom: 1.5rem;
}
.pb-4 {
  padding-bottom: 2rem;
}
.pb-5 {
  padding-bottom: 3rem;
}
.pb-6 {
  padding-bottom: 4rem;
}
.pb-7 {
  padding-bottom: 4.5rem;
}
.pb-8 {
  padding-bottom: 6rem;
}


/*padding left*/
.pl-1 {
  padding-left: .5rem;
}
.pl-2 {
  padding-left: 1rem;
}
.pl-3 {
  padding-left: 1.5rem;
}
.pl-4 {
  padding-left: 2rem;
}
.pl-5 {
  padding-left: 3rem;
}
.pl-6 {
  padding-left: 4rem;
}
.pl-7 {
  padding-left: 4.5rem;
}
.pl-8 {
  padding-left: 6rem;
}

/*padding right*/
.pr-1 {
  padding-right: .5rem;
}
.pr-2 {
  padding-right: 1rem;
}
.pr-3 {
  padding-right: 1.5rem;
}
.pr-4 {
  padding-right: 2rem;
}
.pr-5 {
  padding-right: 3rem;
}
.pr-6 {
  padding-right: 4rem;
}
.pr-7 {
  padding-right: 4.5rem;
}
.pr-8 {
  padding-right: 6rem;
}


/*margin top e bottom*/
.my-1 {
  margin: .5rem 0;
}
.my-2 {
  margin: 1rem 0;
}
.my-3 {
  margin: 1.5rem 0;
}
.my-4 {
  margin: 2rem 0;
}
.my-5 {
  margin: 3rem 0;
}
.my-6 {
  margin: 4rem 0;
}
.my-7 {
  margin: 4.5rem 0;
}
.my-8 {
  margin: 6rem 0;
}

/*margin left e right*/
.mx-1 {
  margin: 0 0.5rem;
}
.mx-2 {
  margin: 0 1rem;
}
.mx-3 {
  margin: 0 1.5rem;
}
.mx-4 {
  margin: 0 2rem;
}
.mx-5 {
  margin: 0 3rem;
}
.mx-6 {
  margin: 0 4rem;
}
.mx-7 {
  margin: 0 4.5rem;
}
.mx-8 {
  margin: 0 6rem;
}

/*margin top*/
.mt-1 {
  margin-top: .5rem;
}
.mt-2 {
  margin-top: 1rem;
}
.mt-3 {
  margin-top: 1.5rem;
}
.mt-4 {
  margin-top: 2rem;
}
.mt-5 {
  margin-top: 3rem;
}
.mt-6 {
  margin-top: 4rem;
}
.mt-7 {
  margin-top: 4.5rem;
}
.mt-8 {
  margin-top: 6rem;
}

/*margin bottom*/
.mb-1 {
  margin-bottom: .5rem;
}
.mb-2 {
  margin-bottom: 1rem;
}
.mb-3 {
  margin-bottom: 1.5rem;
}
.mb-4 {
  margin-bottom: 2rem;
}
.mb-5 {
  margin-bottom: 3rem;
}
.mb-6 {
  margin-bottom: 4rem;
}
.mb-7 {
  margin-bottom: 4.5rem;
}
.mb-8 {
  margin-bottom: 6rem;
}


/*margin left*/
.ml-1 {
  margin-left: .5rem;
}
.ml-2 {
  margin-left: 1rem;
}
.ml-3 {
  margin-left: 1.5rem;
}
.ml-4 {
  margin-left: 2rem;
}
.ml-5 {
  margin-left: 3rem;
}
.ml-6 {
  margin-left: 4rem;
}
.ml-7 {
  margin-left: 4.5rem;
}
.ml-8 {
  margin-left: 6rem;
}

/*margin right*/
.mr-1 {
  margin-right: .5rem;
}
.mr-2 {
  margin-right: 1rem;
}
.mr-3 {
  margin-right: 1.5rem;
}
.mr-4 {
  margin-right: 2rem;
}
.mr-5 {
  margin-right: 3rem;
}
.mr-6 {
  margin-right: 4rem;
}
.mr-7 {
  margin-right: 4.5rem;
}
.mr-8 {
  margin-right: 6rem;
}
