@import "global.css";
@import "buttons.css";

/*responsividade dos elementos de texto*/

/*telas menores que 768px*/
@media only screen and (max-width: 768px) {
  .title-h1 {
    font-weight: 500;
    font-size: 4em; 
    letter-spacing: 2%;
    line-height: auto;
  }

  .title-h2 {
    font-weight: 500;
    font-size: 3em; 
    letter-spacing: 1.5%;
    line-height: auto;
  }

  .title-h3 {
    font-weight: 500;
    font-size: 2.5em; 
    letter-spacing: 1.25%;
    line-height: auto;
  }

  .title-h4 {
    font-weight: 400;
    font-size: 1.5em; 
    letter-spacing: 1%;
    line-height: auto;
  }

  .title-h5 {
    font-weight: 300;
    font-size: 1em; 
    letter-spacing: 0.75%;
    line-height: auto;
  }
}

/*telas menores que 428px*/
@media only screen and (max-width: 428px) {
  .title-h1 {
    font-weight: 500;
    font-size: 3.5em; 
    letter-spacing: 2%;
    line-height: auto;
  }

  .title-h2 {
    font-weight: 500;
    font-size: 2.5em; 
    letter-spacing: 1.5%;
    line-height: auto;
  }

  .title-h3 {
    font-weight: 500;
    font-size: 2em; 
    letter-spacing: 1.25%;
    line-height: auto;
  }

  .title-h4 {
    font-weight: 400;
    font-size: 1.5em; 
    letter-spacing: 1%;
    line-height: auto;
  }

  .title-h5 {
    font-weight: 300;
    font-size: 1em;
    letter-spacing: 0.75%;
    line-height: auto;
  }
}

/*responsividade dos botões*/
@media only screen and (max-width: 428px) {
  .btn {
    max-width: 18.75em;
    width: 100%;
    padding: .6rem;
    border-radius: 4px;
  }
}
