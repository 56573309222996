@import url(../../../styles/global.css);
@import url(../../../styles/utility-classes.css);

.footer_container {
  margin-top:280px;
  padding: 12px;
  height: 325px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.footer_container h3 {
  color: var(--white-scale-200);
}
.for_companies h3 {
  color: var(--white-scale-200);
}
.for_candidates {
  display: flex;
  flex-direction: column;
}
.for_candidates h3 {
  color: var(--white-scale-200)
}
.for_companies a {
  color: var(--white-scale-300);
}
.for_candidates h3 {
  color: var(--white-scale-200)
}
.for_contributors {
  display: flex;
   flex-direction: column;
}
.for_contributors h2 {
  color: var(--white-scale-300);
  font-size:28px
}
.for_contributors small {
  color: var(--white-scale-300);
  font-size:12px
}
.for_contributors small a {
  color: var(--white-scale-300);
}
.want_to_contribute {
  margin-top: 24px;
  background-color: var(--green-scale-200);
  width: 337px;
  height: 40px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  color: var(--white-scale-300);
  font-weight: 600;
  cursor: pointer;
}
.for_contributors h4 {
  color: var(--white-scale-200);
  margin: 8px 0;
}
.row_footer{
  height: 675px;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--blue-scale-300);
}

.color-white {
  margin-bottom: 8px;
  color: var(--white-scale-200);
}
.text-underline {
  text-decoration: underline;
}

h3 {
  color: var(--white-scale-300);
  margin-bottom: 8px;
}

h4 {
  font-weight: 500;
}
a {
  font-size: 12px;
}

.socialmedia-icon {
   width: 28px;
  margin-right: 8px;
  cursor: pointer;
}