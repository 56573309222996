.container-header-ourteam {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
.grid-our-team-boxes {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top:48px;
}
.grid-our-team-boxes-secondary {
  display: flex;
flex-direction: row;
justify-content: space-around;
  align-items: center;
  margin-top:24px
}