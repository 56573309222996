@import "../../../../styles/global.css";
@import "../../../../styles/utility-classes.css";

.input-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  max-width: 23em;
  width: 80%;
  height: 220px;
  margin-top: 24px;
}

.google-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  max-width: 23em;
  width: 70%;
  margin-bottom: 24px;
}

.google-container-text {
  color: var(--black-scale-300);
  font-weight: 500;
  line-height: 1.25rem;
  text-align: left;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  max-width: 25em;
  width: 85%;
  font-size: 14px;
}

.form-container {
  background-color: var(--white-scale-100);
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 22em;
  width: 45%;
  height: 50%;
  padding: 24px;
}
.form-container-text-description {
  font-size: 11px;
  text-align: center;
  color: var(--black-scale-200);
}
