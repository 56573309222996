@import url("../../styles/global.css");

.payment-general-container {
  width: 100vw;
  height: 70vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.payment-middle-container {
  margin: 32px;
  padding: 24px;
  background-color: var(--white-scale-200);
  display: flex;
  flex-direction: column;
  width: 30%;
  min-width: 300px;
  height: 100vh;
  border-radius: 24px;
  align-items: center;
}
.payment-title-description-container {
  margin-bottom: 24px;
  width: 85%;
}
.payment-textfield-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-bottom: 4px;
}
.payment-middle-container h3 {
  color: var(--blue-scale-300);
  font-weight: 700;
}
.payment-middle-container p {
  color: var(--blue-scale-300);
  font-weight: 600;
  margin-bottom: 16px;
}
.payment-middle-container small {
  font-size: 12px;
  margin-bottom: 16px;
  color: var(--black-scale-200);
}

.payment-middle-container label {
  color: var(--black-scale-200);
}

.payment-text-exp-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.payment-textfield-container label {
  font-size: 12px;
  margin-bottom: 8px;
}
