@import "../../../../styles/global.css";
@import "../../../../styles/utility-classes.css";

.background-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  height: 100%;
  width: 50%;
}

.background-img {
  width: 250px;
  /* height: 376px; */
}

.background-text {
  color: var(--white-scale-100);
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 39px;
  text-align: center;
  letter-spacing: 0.02em;
  max-width: 400px;
}
